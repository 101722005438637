var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-notice"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"notice-item"},[_c('div',{staticClass:"card-state"},[(_vm.item.status == 1)?_c('img',{attrs:{"src":require("@/assets/search/baojiazhong.png"),"alt":""}}):_vm._e(),_vm._v(" "),(_vm.item.status == 3)?_c('img',{attrs:{"src":require("@/assets/search/baojiajieshu.png"),"alt":""}}):_vm._e(),_vm._v(" "),(_vm.item.status == 4)?_c('img',{attrs:{"src":require("@/assets/search/yiwancheng.png"),"alt":""}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"item-header"},[_c('div',{staticClass:"head-title"},[_c('div',{staticClass:"head-msg no-waper"},[_vm._v(_vm._s(_vm.item.title))]),_vm._v(" "),_c('div',{staticClass:"head-text no-waper"},[_c('span',[_vm._v("需求方：")]),_vm._v(" "),(_vm.item.business_name)?_c('span',[_vm._v(_vm._s(_vm.item.business_name))]):_c('span',[_vm._v(_vm._s(_vm.item.publisherName))])])])]),_vm._v(" "),_c('div',{staticClass:"cell-main"},[_c('div',{staticClass:"main-header line-height"},[_c('div',[_vm._v("类目")]),_vm._v(" "),_c('div',[_vm._v("\n            "+_vm._s(_vm.item.type == 1 ||
              _vm.item.type == 2 ||
              _vm.item.type == 3 ||
              _vm.item.type == 7
                ? "租赁数量"
                : "租赁面积")+"\n          ")]),_vm._v(" "),_c('div',[_vm._v("期望租金")]),_vm._v(" "),_c('div',[_vm._v("租赁时长")])]),_vm._v(" "),_c('div',{staticClass:"main-context"},[_c('div',{staticClass:"context-item"},[_c('div',[_vm._v(_vm._s(_vm.rendType[_vm.item.type]))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.item.numOrArea ? _vm.item.numOrArea : "暂无信息"))]),_vm._v(" "),_c('div',[_vm._v("\n              "+_vm._s(_vm.item.negotiable == 1 || !_vm.item.price || _vm.item.price < 0
                  ? "面议"
                  : _vm.item.priceUnit
                  ? _vm.item.price + _vm.item.priceUnit
                  : _vm.item.price + "元/" + _vm.item.rentalDurationUnit)+"\n            ")]),_vm._v(" "),_c('div',[_vm._v("\n              "+_vm._s(_vm.item.rentalDuration == -1 || !_vm.item.rentalDuration
                  ? "面议"
                  : _vm.item.rentalDuration + "" + _vm.item.rentalDurationUnit)+"\n            ")])])])]),_vm._v(" "),_c('div',{staticClass:"main-footer"},[_c('div',{staticClass:"footer-item no-waper"},[_c('img',{attrs:{"src":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAiCAYAAABMfblJAAADi0lEQVRYR7WXXWgcVRiG33c6Kc1FBEutNcQfELUq1SYzEZOd3cxU7wRF1GBBiAoWVCwKRS9Kb0REKBSrIP6gll6IWgtqBEExM5ud3cbsSUurtjel/oAR0gu1ViLNZr4yu0lMsztzZm3du2We733m7Nn5zjdEho/vq3UwsZHkgEBuJ3BVo4xnCTkuwoqYxnfeQO9PujimAWPj1ZxBjoB4BECnLgzglwT2DTnWx0lsS2EYhl01rnkFgqf0kpbEV5EYz2/J9x1debVJ6JcnNzMyRkH0/EfZv2XkNjdnvbM85wJhsVLtlYhT8eZctGwxQPC0m7ffWPy6FOz7kxvYYfwMYPUlky0EEXLfkNP/ef1vthgehGocQP5SyxbyZucN84a7Bjf/WhcWS9XHhXz3f5I1YoUfunlra10YhOoHALdkEJ4RYJTAKRGJQGMDIS6AmzLUisxF3RwrTVkGRekKCB5kJM8UCvZvK9kgVC8C2KXLgGAPg5LaC2K7Bj7gOvZwGlMM1YgA+zQ5xxiE6hCAO1PA313HXqu9+8bW6LKmY+EvAK5OChTIR57T/3AmYam6FeQHKeyZWDgD4IpkiDtdx3o5i9CvqI2McCKFnY2Fcb+7LXGFxF4vZz+bRVhv9gbDFPY0i6HaLcCOFOi469i3ZhEG5epbEG5LZInD/Kasrl8lOJkWGFEe3JLrP5jGVCqVteei1fEjk9waKS80HvzSVBmUwdRVCO9189ZoK8Y/dOQ6zs8HAK5NzBD8ue7yNevrwrFy9QFD+EmGn22/CA90dsyN12o1OSedN9KQh9g4N7vS6gm8OeTYTy4172KoQgFyGaQx8lfcHQFclpEXE0aP4/RNLwnHK9U7oojfZgxoCxPBLi9vvxQXXXDQBqXqeyAfaytND8/MTJ/qHh4enm8SKqU6zv6DvwF06HOyEQIOeI41sUg3zzSlybtJ4+tscVpqv+vYI8uplrNLUFJvg3hCG5cOnHYde/1KJHFYauNQbqk1IliFgn04s9CfmOhhzfwRgNnuSgV8znOsV1vVpU/eZXWPIfiiTWHTvmn3cDngh2oHgd0Zpd+7jr1J03H0UUGo3gfwqIaclblV3Z7X+8dFC+MAXYOnIX1Dg/1HdLefeaT3fd+k2XUMxM0rQyXC/V7B/lQna+o0uoKF14G4316zxBLb3Zz9uq42sdPoCutS0/gMxJUg9rg5+zVdzfLr5wF1/zLrfnf1lwAAAABJRU5ErkJggg=="}}),_vm._v(" "),_c('span',{staticClass:"label"},[_vm._v("工作地：")]),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.item.taskAddr ? _vm.item.taskAddr : _vm.item.address))])]),_vm._v(" "),_c('div',{staticClass:"footer-item no-waper"},[_c('span',{staticClass:"label"},[_vm._v("发布时间：")]),_vm._v(" "),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("datanumber2datetime")(_vm.item.updatetime,"yyyy-MM-dd")))])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }