//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
import env from "@/../env.js";
import cookie from "@/storage/cookies.js";
import defult from "@/assets/defult.png";
import localStorage from "@/storage/localStorage.js";
import { httpMap } from "@/api/httpMapPositiom.js";
import { getPosition } from "@/pages/Home/components/goods/mapPosition.js";

import CommonTag from "../commonTag";
import NoData from "../noData";
import LeaseCell from "./ItemCell/LeaseCell";
import NeedHall from "./ItemCell/needHallList";
import TabList from "@/components/TabList/index.vue";
import comTBSke from "../skeComponents/comTBSke.vue";
import comTabSke from "../skeComponents/comTabSke.vue";
import comNeedSke from "../skeComponents/comNeedSke.vue";
export default {
  components: {
    CommonTag,
    NoData,
    LeaseCell,
    TabList,
    NeedHall,
    comTBSke,
    comTabSke,
    comNeedSke

    // TabList: () => import("../flowModel/components/TabList"),
  },
  data: function() {
    return {
      defult,
      allType: [],
      childType: [],
      selectChildType: {},
      area: {},
      showFoldIcon: false,
      showMoreIcon: false,
      jixiePageSize: 1,
      equipmentPageSize: 1,
      carPageSize: 1,
      housePageSize: 1,
      allTypeText: [], // Tab级菜单列表
      activeIndex: 0, // Tab列表index
      load: true, // loading状态
      list: [], // 列表数据
      menuInfo: {}, // 二级菜单数据存储
      menuIndex: 0, // 二级菜单index
      menuList: [
        { name: "全部", value: "" },
        { name: "机械租赁", value: "1" },
        { name: "设备租赁", value: "2" },
        { name: "车辆租赁", value: "3" },
        { name: "房产租赁", value: "4" },
        { name: "土地租赁", value: "5" },
        { name: "工厂租赁", value: "6" },
        { name: "农机租赁", value: "7" }
      ],
      menuNeedList: [
        { name: "全部", value: "" },
        { name: "机械求租", value: "1" },
        { name: "设备求租", value: "2" },
        { name: "车辆求租", value: "3" },
        { name: "房产求租", value: "4" },
        { name: "土地求租", value: "5" },
        { name: "工厂求租", value: "6" },
        { name: "农机求租", value: "7" }
      ],
      // 二级菜单列表
      categoryNo: "", // 菜单no
      currentPage: 1,
      isClose: false, // 是否点击收起
      isMore: false, // 是否加载更多
      isLoadData: false, // 是否还有跟多数据请求
      isEmpty1: false
    };
  },
  created() {
    if (process.client) {
      this.allTypeText = ["出租大厅", "需求大厅", "平台自营"];
      this.changeTag(0)
      // this.getAllLeaseModel()
      this.refreshData();
    }
  },
  mounted() {},
  async fetch() {},
  methods: {
    // 收起
    refreshData() {
      console.log(this.isEmpty1, "refreshData");
      this.currentPage = 1;
      this.isClose = false;
      this.isMore = false;
      this.list = [];
      this.getGoodsList();
      // this.getOutLeaseList();
    },
    // 加载更多数据
    loadMore() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.isClose = true;
        this.getGoodsList(true);
      }
    },
    getImg(img) {
      img = img || "";
      const flag = img.indexOf(",") !== -1;
      const res = flag ? img.split(",")[0] : img;
      return res;
    },
    // 获取租赁服务类型
    async getRentTypeList(text) {
      const res = await apiHome.getRentTypeList();
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const findData = data.find(item => item.name === text);
        const list =
          findData &&
          findData.children.map(item => {
            return {
              name: item.name,
              value: item.no
            };
          });
        this.menuList = [{ name: "全部", value: "" }, ...list];
      }
    },
    // 切换tab栏
    changeTag(index) {
      this.activeIndex = index;
      this.menuList = [];
      this.categoryNo = "";
      setTimeout(async () => {
        this.getAllLeaseModel()
        this.refreshData();
      });
    },
    async getAllLeaseModel(){
      let res = await apiHome.getAllLeaseModel()
      this.menuList = res.data.data.map(item=>{
        return {
          name:item.name,
          value: +item.type === 0 ? '' : item.type
        }
      })
    },
    // 点击二级菜单选项
    clickTabItem(item) {
      this.isEmpty1 = false;
      const { value } = item;
      this.categoryNo = value;
      this.refreshData();
      // this.isEmpty1 = this.list.length == 0;
    },
    /**
     * 获取土地二级分类列表
     */
    async getLandSecond() {
      const res = await apiHome.getLandSecond();
      const { data, msg, code } = res.data;
      if (code === 200) {
        const list =
          data &&
          data.map(item => {
            return {
              name: item.name,
              value: item.no
            };
          });
        this.menuList = [{ name: "全部", value: "" }, ...list];
      }
    },
    /**
     * 获取工厂二级分类列表
     */
    async getPlantSecond() {
      const res = await apiHome.getPlantSecond();
      const { data, msg, code } = res.data;
      if (code === 200) {
        const list =
          data &&
          data.map(item => {
            return {
              name: item.name,
              value: item.no
            };
          });
        this.menuList = [{ name: "全部", value: "" }, ...list];
      }
    },
    /**
     * 获取农机二级分类列表
     */
    async getFarmSecond() {
      const res = await apiHome.getFarmSecond();
      const { data, msg, code } = res.data;
      if (code === 200) {
        const list =
          data &&
          data.map(item => {
            return {
              name: item.name,
              value: item.no
            };
          });
        this.menuList = [{ name: "全部", value: "" }, ...list];
      }
    },
    // 获取公司companyNos信息
    async getCompanyNosInfo() {
      const res = await apiHome.getCompanyNos();
      const { data, code } = res.data;
      if (+code == 200) {
        const companyNos = data;
        return companyNos;
      }
    },
    // 租赁大厅列表
    async getOutLeaseList(type = "", isLoadMore = false) {
      this.position = this.currentArea = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : {};
      let pramas = {
        areaNos: [],
        cityNo: "",
        pageNum: this.currentPage,
        pageSize: 12,
        provinceNo: "",
        title: "",
        type: type,
        longitude: +this.position.lng,
        latitude: +this.position.lat,
        distanceSort: "2",
        sortWay: 1
      };
      if (this.activeIndex == 2) {
        const companyNo = await this.getCompanyNosInfo();
        pramas = Object.assign(pramas, { companyNos: companyNo });
      }
      const res = await apiHome.getOutLeaseList(pramas);
      const { data, msg, code } = res.data;
      console.log(data, code, "查看租赁平台自营数据")
      if (+code === 200) {
        const records = data.records || [];
        console.log(records, "recordsrecordsrecordsrecords")
        const list =
          records.length &&
          records.map(item => {
            return {
              ...item,
              imgField: this.getImg(item.pictures),
              localField: item.address,
              titleField: item.title,
              priceField:
                +item.negotiable !== 1 ? item.price + item.priceUnit : "面议",
              timeField: +item.updatetime,
              selfField: item.isSelf
            };
          });
        this.isLoadData = records.length === 12;
        this.list = isLoadMore ? this.list.concat(list) : list;
        this.isMore = this.currentPage < data.totalPage;
        this.isEmpty1 = !this.list || this.list.length == 0;
        // console.log(this.isEmpty1, this.list.length);
        this.load = false;
        console.log('最重渲染',  this.list)
        return;
      }
      this.load = false;
    },
    async getAllRentLeaseList(type = "", isLoadMore = false) {
      const parmas = {
        areaNos: [],
        current: this.currentPage,
        limit: 6,
        provinceNo: "",
        cityNo: "",
        status: "",
        title: "",
        type: type,
        sortWay: 1
      };
      const res = await apiHome.getAllRentLeaseInfo(parmas);
      const { data, code, msg } = res.data;
      if (+code == 200) {
        const records = data.records || [];
        const list =
          records.length &&
          records.map(item => {
            return {
              ...item
            };
          });
        this.isLoadData = records.length === 6;
        this.list = isLoadMore ? this.list.concat(list) : list;
        this.isMore = this.currentPage < data.totalPage;
        this.isEmpty1 = !this.list || this.list.length == 0;
        this.load = false;
        return;
      }
      this.load = false;
    },

    // 获取商品列表
    getGoodsList(isLoadMore = false) {
      this.load = true;
      const index = this.activeIndex;
      const text = this.categoryNo;
      switch (index) {
        case 0:
          this.getOutLeaseList(text, isLoadMore);
          break;
        case 1:
          this.getAllRentLeaseList(text, isLoadMore);
          break;
        case 2:
          this.getOutLeaseList(text, isLoadMore);
          break;
        default:
          break;
      }
    },
    // 获取土地列表
    async getLandList() {
      const parmas = {
        pageNum: this.currentPage,
        pageSize: 12,
        firstCategoryNo: this.categoryNo,
        updateTime: "-1"
      };
      const res = await apiHome.getLandList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const records = data.records || [];
        const list =
          (records.length &&
            records.map(item => {
              return {
                ...item,
                imgField: this.getImg(item.pictures),
                localField: item.province + item.city + item.area,
                titleField: item.title,
                priceField:
                  +item.negotiable !== 1
                    ? item.rentalPrice + item.rentalSelectPriceName
                    : "面议",
                timeField: +item.createTime
              };
            })) ||
          [];
        this.isLoadData = records.length === parmas.pageSize;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < data.pages;
        this.isEmpty1 = this.list.length == 0;
        this.load = false;
      }
      this.load = false;
    },
    // 获取工厂表
    async getPlantList() {
      const parmas = {
        pageNum: this.currentPage,
        pageSize: 12,
        firstCategoryNo: this.categoryNo
      };
      const res = await apiHome.getPlantList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const records = data.records || [];
        const list =
          (records.length &&
            records.map(item => {
              return {
                ...item,
                imgField: item.picture,
                localField: item.taskAddr,
                titleField: item.title,
                priceField: item.rentalPrice,
                timeField: +item.createTime
              };
            })) ||
          [];
        this.isLoadData = records.length === parmas.pageSize;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < data.pages;
        this.isEmpty1 = this.list.length == 0;
        this.load = false;
      }
      this.load = false;
    },
    // 获取农机列表
    async getFarmList() {
      const parmas = {
        pageNum: this.currentPage,
        pageSize: 12,
        firstCategoryNo: this.categoryNo
      };
      const res = await apiHome.getFarmList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const records = data.records || [];
        const list =
          (records.length &&
            records.map(item => {
              return {
                ...item,
                imgField: item.picture,
                localField: item.taskAddr,
                titleField: item.title,
                priceField: item.rentalPrice,
                timeField: +item.createTime
              };
            })) ||
          [];
        this.isLoadData = records.length === parmas.pageSize;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < data.pages;
        this.isEmpty1 = this.list.length == 0;
        this.load = false;
      }
      this.load = false;
    },
    // 工程机械
    async getRentJixieList() {
      let res =
        (await apiHome.getRentJixieList(
          {},
          {
            pageNum: this.currentPage,
            pageSize: 12,
            timeSort: 0,
            firstCategoryNo: this.categoryNo
          }
        )) || {};
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const records = data.dataList || [];
        const list =
          (records.length &&
            records.map(item => {
              return {
                ...item,
                imgField: this.getImg(item.pictures),
                localField: item.provinceName + item.cityName + item.areaName,
                titleField: item.title,
                priceField:
                  +item.negotiable !== 1
                    ? item.rentalPrice + item.priceUnitName
                    : "面议",
                timeField: +item.createTime || +item.updateTime
              };
            })) ||
          [];
        this.isLoadData = records.length === 12;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < data.totalPage;
        this.isEmpty1 = this.list.length == 0;
        this.load = false;
      }
      this.load = false;
    },
    // 工程设备
    async getRentEquipmentList() {
      let res =
        (await apiHome.getRentEquipmentList(
          { page: this.currentPage, limit: 12 },
          {
            brandNo: "",
            cityList: [],
            firstCategory: this.categoryNo,
            provinceNo: "",
            secondCategory: "",
            sysAttrMapList2: [],
            timeSort: 1
          }
        )) || {};
      const { data, status, pages = 0 } = res;
      if (+status === 200) {
        const records = data.records || [];
        const list =
          (records.length &&
            records.map(item => {
              return {
                ...item,
                imgField: this.getImg(item.pictures),
                localField:
                  item.provinceName + item.cityName + item.districtName,
                titleField: item.title,
                priceField: item.pricerange,
                timeField: +item.createTime
              };
            })) ||
          [];
        this.isLoadData = records.length === 12;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < pages;
        this.isEmpty1 = this.list.length == 0;
        this.load = false;
      }
      this.load = false;
    },
    // 车辆租赁
    async getRentcarList() {
      const parmas = {
        additionType: [],
        area: [],
        province: "",
        brandNo: "",
        city: "",
        cost: 0,
        modelNo: this.categoryNo,
        pageNum: this.currentPage,
        pageSize: 12,
        state: 1,
        type: "",
        updateSort: -1,
        additionType: []
      };
      const res = await apiHome.getRentcarList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const records = data.dataList || [];
        const list =
          (records.length &&
            records.map(item => {
              return {
                ...item,
                imgField: this.getImg(item.picture),
                localField:
                  item.provinceName + item.cityName + item.districtName,
                titleField: item.title,
                priceField: item.price,
                timeField: +item.createTime
              };
            })) ||
          [];
        this.isLoadData = records.length === parmas.pageSize;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < data.totalPage;
        this.isEmpty1 = this.list.length == 0;
        this.load = false;
      }
      this.load = false;
    },
    // 房产祖灵
    async getRentHouseList() {
      const pramas = {
        area: "",
        areaNoList: [],
        categoryChildId: "",
        categoryParentId: this.categoryNo,
        pageNum: this.currentPage,
        pageSize: 12,
        provinceNo: "",
        rentalArea: "0,1000",
        rentalPrice: "",
        title: "",
        updateTime: "0"
      };
      const res = await apiHome.getRentHouseList(pramas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const records = data.records || [];
        const list =
          (records.length &&
            records.map(item => {
              return {
                ...item,
                imgField: this.getImg(item.pictures),
                localField: item.provinceName + item.cityName + item.areaName,
                titleField: item.title,
                priceField:
                  +item.negotiable !== 1
                    ? item.rentalPrice + item.rentalSelectPriceName
                    : "面议",
                timeField: +item.createTime
              };
            })) ||
          [];
        this.isLoadData = records.length === pramas.pageSize;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < data.pages;
        this.isEmpty1 = this.list.length == 0;
        this.load = false;
      }
      this.load = false;
    },
    detail(item) {
      // debugger;
      let openUrl = "";
      const baseUrl = env[process.env.environment].VUE_APP_RENTALMARKET;
      const index = this.activeIndex;
      const type = item.type;
      switch (index) {
        case 0: // 出租大厅
        case 2: // 出租大厅
          switch (type * 1) {
            case 1: // 工程机械
              openUrl = `${baseUrl}/rentalMachineDetails?no=${item.no}`;
              break;
            case 2: // 工程设备
              openUrl = `${baseUrl}/rentalDetails/?id=${item.dataId}&no=${item.dataId}`;
              break;
            case 3: // 车辆租赁
              openUrl = `${baseUrl}/carsRentalDetail?id=${item.dataId}`;
              break;
            case 4: // 房产租赁
              openUrl = `${baseUrl}/HousesLeaseDetails?id=${item.dataId}`;
              break;
            case 5: // 土地租赁
              openUrl = `${baseUrl}/landDetails?id=${item.dataId}`;
              break;
            case 6: // 工厂租赁
              openUrl = `${baseUrl}/factoryDetails?id=${item.dataId}`;
              break;
            case 7: // 农机租赁
              openUrl = `${baseUrl}/farmMachineryDetails?id=${item.dataId}`;
              break;
            default:
              openUrl = `${baseUrl}/customLeaseDetails?no=${item.dataId}`
          }
          break;
        case 1: // 需求大厅
          openUrl = `${baseUrl}/DemandHallDetail?id=${
            item.dataId
          }&type=${item.type - 1}`;
          break;
        // case 2: // 自营租赁
        //   openUrl = `${baseUrl}/rentalMachineDetails?id=${item.dataId}&typeNo=${item.first_category}&no=${item.no}`;
        //   break;
      }
      window.open(openUrl);
    },
    /**@name 更多操作 */
    loadingMore() {
      let item = this.selectChildType;
      switch (this.activeIndex) {
        case 0:
          this.jixiePageSize++;
          this.getRentJixieList(item.no);
          break;
        case 1:
          this.equipmentPageSize++;
          this.getRentEquipmentList(item);
          break;
        case 2:
          this.carPageSize++;
          this.getRentcarList(item.no);
          break;
        default:
          this.housePageSize++;
          this.getRentHouseList(item.no);
          break;
      }
    }
  },
  computed: {
    // isEmpty1() {
    //   return this.list.length == 0&&!this.load;
    // },
  },
  watch: {
    isEmpty1(a, b) {
      console.log(a, b);
    }
  }
};
