//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ZjunInquiryDialog } from 'zjkjui'
import { apiHome } from "@/api/apiHome";
import { modulesEnum, exchangeEnum } from "./../quickEntry";
import dialogModules from "./../dialogModules";
// import cookie from "@/storage/cookies.js";
import link from "@/public/link.js";
import admin from "@/assets/admin.png";
import env from "@/../env.js";
export default {
  name: "modulesContent",
  data() {
    return {
      admin,
      link,
      modules: modulesEnum,
      exchangeList: exchangeEnum,
      dialogModules: dialogModules,
      liWidth: "33",
      dialogVisible: false,
      // isTeam: false, //是否进行团队认证
      // isSuperAdmin: false // 是否为炒鸡管理员 企业创建着
      // driverAuthState: 0  // 0 待审核 1 审核通过 2 审核不通过
    };
  },
  computed: {
    userInfo: function () {
      let user = this.$store.state.user
      console.log(user);
      // const teamAuthStatus = user && user.teamAuthStatus ? user.teamAuthStatus : 0;
      // this.isTeam = teamAuthStatus && +teamAuthStatus === 2
      return user
    },
    driverInfo: function () {
      return this.$store.state.driverInfo;
    },
    authUser() {
      return this.$store.state.authUser;
    },
    relevance() {
      return this.$store.state.relevance;
    },
    relevanceAdmin() {
      return this.$store.state.relevanceAdmin;
    },
    isTeam(){
      console.log('this.$store.state.teamAuthStatus', this.$store.state.teamAuthStatus)
      return this.$store.state.teamAuthStatus ? true : false 
    }
  },
  mounted() {
    this.$bus.$off("clickSearchReleaseBtn").$on("clickSearchReleaseBtn", () => {
      if (!this.$store.state.loginType) {
        let data = {
          callBack: function () {
            this.$store.dispatch("setUser");
            this.pushThis();
          }.bind(this)
        };
        this.$_login(data);
      } else {
        this.dialogVisible = true
      }
    });
  },
  methods: {
    // 跳转注册
    toSign() {
      window.open(`${env[process.env.environment].VUE_APP_PERSONURL}/sign`);
    },
    // 跳转认证页面
    toCertification() {
      window.location.href = `${env[process.env.environment].VUE_APP_PERSONURL
        }/authenticationManagement`;
    },
    // 跳转个人中心
    toPersonal() {
      // console.log(this.$store.state.loginType)
      if (this.$store.state.loginType && this.$store.state.loginType) {
        link.personal();
      } else {
        this.loginIndex();
      }
    },
    pushThis() {
      let that = this
      this.pushUserinfo = false
      this.$nextTick(function () {
        that.pushUserinfo = true
      })
    },
    loginIndex(item = {}) {
      // alert(item)
      console.log(`k:${item.name}--v:${item.path}`)
      const { loginType } = this.$store.state // 用户是否登录
      let isCompanyAuth = item.isCompanyAuth || null //是否为企业权限
      console.log('isCompanyAuth:', isCompanyAuth)
      // {expired}
      // 免登录跳转
      const whiteList = ['竞价采购', '招标大厅', '企业集采', '需方发起', '租赁需求', '货运大厅', '竞价找车', '企业大全', '知识产权', '专业代办', "服务大厅", "竞价货运", "金融服务", "商品需求"]
      // 企业账户
      const releaseList = dialogModules.map(item => item.name)
      const comList = ['我的企业', '我的询价', '我的招采', '采购竞价', '劳务用工', '会员经营', '工队发布', ...releaseList]
      const personSingo = ['集采报价', '临时用工']
      const { identityType, personalAuth, mainComAuth, type, companyNo, no, teamNo, organizationNo } = this.userInfo
      const relevance = this.relevance
      const { isAdministrators, isSuperAdmin } = this.relevanceAdmin // 管理员 炒鸡管理员（企业创建着）
      const authMenuList = this.authUser.identifiers // 菜单权限列表
      // let isVipUser =  this.$store.state.isVipCard // 是否为缴费用户
      // let vipRouteList = []
      console.log('identityType, relevance, personalAuth, isAdministrators, mainComAuth , type , companyNo,no,teamNo,organizationNo')
      console.log(identityType, relevance, personalAuth, isAdministrators, mainComAuth, type, companyNo, no, teamNo, organizationNo)
      
      if (!loginType && !whiteList.includes(item.name)) {
        let data = {
          callBack: function () {
            this.$store.dispatch("setUser")
            // this.pushThis()
          }.bind(this)
        };
        this.$_login(data)
        return
      }
      if(item.name == '询价发布') {
        if(authMenuList.includes('OurInquiryPriceList')) {
          ZjunInquiryDialog({
            queryObj: { // 首页打开默认参数
              from:"home",
              type:"new"
            },
            userInfo: this.userInfo,
            authUser: this.authUser
          })
        } else {
          this.$message.warning('您没有权限，请联系管理员开通！')
        }
        return
      }
      if (item.name == '我的企业' && type == 1) {
        window.open(`${item.path}/UserEnterpriseList`)
        return
      }
      if (item.name == "用户中心") {
        window.open(`${item.path}`)
        return
      }
      if (item.name == "我的网站") {
        if (type == 0) {
          window.open(`${item.path}/personWebsite?no=${no}`)
        } else if (type == 1) {
          window.open(`${item.path}/company/1401?no=${companyNo}`)
        } else if (type == 2) {
          window.open(`${item.path}/teamWebsite?no=${teamNo}`)
        } else {
          window.open(`${item.path}/organizationWebsite?no=${organizationNo}`)
        }
        return
      }
      if (item.name == "金融管理" && type == 1) {
        window.open(`${item.path}/financeModify`)
        return
      }
      if (item.name == '需求预算') {
        type == 1 ? window.open(`${item.path}/commodityDemand`) : this.$message.warning("请切换至企业身份，无企业请先加入/认证企业！");
        return
      }
      if (item.name == "劳务用工" && (type == 1 || type == 2)) {
        window.open(`${item.path}/teamLaborService`)
        return
      }
      if (item.name == "企业资质") {
        type == 3 ? this.$message.warning("请切换至企业身份，无企业请先加入/认证企业！") : window.open(`${item.path}/specialAptitude`)
        return
      }
      if (item.name == "酒店管理") {
        type == 0 ? this.$message.warning("请切换至企业身份，无企业请先加入/认证企业！") : window.open(`${env[process.env.environment].VUE_APP_PERSONURL}/newInfo`)
        return
      }
      if (whiteList.includes(item.name)) {
        window.open(item.path)
        return
      }
      const isDirverPass = this.driverAuth // 是否认证司机信息
      // identityType 0 个人 1企业 2团队 3 政府
      const isSinglePass = +identityType === 0 // 个人用户
      const isRealPass = +personalAuth === 1 // 实名认证
      // 2. 判断企业用户(如果是企业用户就已经实名认证了)
      // || +identityType === 2 && mainComAuth
      const isComSuperAdmin = +identityType === 1 && isAdministrators || +identityType === 1 && isSuperAdmin  // 企业管理员或者主企业
      const isRelevanceCom = +identityType === 1 && relevance // 关联企业
      let companyList = this.$store.state.CompanyOrganization.filter(item => +item.type == 1)//当前用户的企业列表
      // 发布相关验证
      console.log(`司机认证--->11222:${isDirverPass}`,);
      console.log(`是否为个人--->:${isSinglePass}`);
      console.log(`个人实名认证--->:${isRealPass}`);
      console.log(`企业管理员--->:${isComSuperAdmin}`);
      console.log(`关联企业--->:${isRelevanceCom}`);
      console.log(`关联企业列表--->:companyList}`, companyList);
      // if (!isRealPass) {
      //   this.$message.warning("个人未进行实名认证，请认证后操作!");
      //   return
      // }
      if (isSinglePass) {
        if (!isRealPass) {
          this.$message.warning("个人未进行实名认证，请认证后操作!");
          return
        }
        if (personSingo.includes(item.name)) {
          window.open(item.path)
          return
        }
        if (comList.includes(item.name)) {
          // 这四种发布个人实名认证也可发布
          // , '拍卖发布', '预算发布', '商品需求'
          const put = ['机械发布', '设备发布', '车辆发布', '房产发布']
          if (put.includes(item.name)) {
            if (!isRealPass) {
              this.$message.warning("个人未进行实名认证，请认证后操作!");
              return
            }
            window.open(item.path)
            return
          }
          this.$message.warning("请切换至企业身份，无企业请先加入/认证企业！");
          return
        }
        // 机械模块都可以跳转 | 工队模块除工程发布外都可以跳转
        if (!isRealPass) {
          this.$message.warning("个人未进行实名认证，请认证后操作!");
          return
        }
        if (authMenuList.includes('sendDemand')) {
          this.$message.warning("请先进行企业认证!");
          return
        }

        if (isCompanyAuth && !companyList.length) {
          this.$message.warning("暂无功能权限，请加入企业或认证企业!")
          return
        }
        if (isCompanyAuth && companyList) {
          this.$message.warning("请切换至企业身份，无企业请先加入/认证企业!")
          return
        }

        if (authMenuList.includes('addCarPush') || authMenuList.includes('PublishGoods')) {
          if (!isDirverPass) {
            this.$message.warning("司机未实名认证，请认证后操作!");
            return
          }
          window.open(item.path)
          return
        }

        if (!authMenuList.includes(item.pathAfter)) {
          this.$message.warning("无权限，请联系管理员!");
          return
        }
        window.open(item.path)
        return
      }
      if (isComSuperAdmin) {
        // 企业管理员 || 主企业
        window.open(`${item.path}`)
        return
      }
      if (isRelevanceCom) {
        // 个人关联企业 ===> 根据菜单权限跳转
        if (!authMenuList.includes(item.pathAfter)) {
          this.$message.warning("此功能无权限, 请联系管理员分配权限!")
          return
        }
        if (authMenuList.includes('addCarPush') || authMenuList.includes('PublishGoods')) {
          // if(!isDirverPass) {
          //   this.$message.warning("司机未实名认证，请认证后操作!");
          //   return
          // }
          window.open(item.path)
          return
        }
        window.open(item.path)
        return
      }
    }
  }
};
